<template lang="pug">
	.d-flex.position-relative.payment-result
		.d-flex.flex-column.flex-lg-row.justify-content-center.align-items-center.w-100(v-if="!error && !isPending")
			.d-flex.flex-column.w-100.payment-result__info
				h2.payment-result__title(v-if="link && isBuyTokens") {{ $t('tokens.youBoughtTokens') }}
				h2.payment-result__title(v-else v-html="link ? (info.planDurationForever ? $t('social.hPaymentFinalPaymentOnce') : $t('social.hPaymentFinal')) : $t('finance.hPaymentFailed')")
				.d-flex.flex-column.payment-result__instruction-wrap(v-if="link && !isBuyTokens")
					p.payment-result__instruction(:class="isMobile ? 'b3' : 'b1'") {{ $t('social.hPaymentFinalDescription') }}
					p.payment-result__instruction(:class="isMobile ? 'b3' : 'b1'") {{ $t('social.hPaymentFinalDescription2') }}
				p.payment-result__txt(v-if="link && isBuyTokens" :class="isMobile ? 'b3' : 'b1'") {{ $t('tokens.hPaymentFinalDescription') }}
				a.text-decoration-none.payment-result__open-bot(v-if="link" :href="link" target="_blank")
					span.btn.btn-primary {{ $t('project.openBot') }}
				p.payment-result__contact-us(v-if="!link" :class="isMobile ? 'b3' : 'b1'") {{ $t('pages.contactUsSupport') }}
				p.b3.mb-3.text-grey.payment-result__support {{ link ? $t('pages.ifCantDoTextToSupport') : $t('h1.support') }}
				a.text-decoration-none.payment-result__link(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					pw-btn.payment-result__button(:text="'Telegram'" :icon="'icon-telegram'")
				pw-btn-mail.payment-result__button.payment-result__link.mb-0
				p.mt-3.mb-0(v-if="!link" v-html="$t('pages.sendCountrySupport')" :class="isMobile ? 'b3' : 'b1'")

			.d-flex.position-relative.payment-result__image
				img(src="/assets/img/current/payment-result.png")
				img.payment-result__image-payment(:src="`/assets/img/current/payment-${link ? 'success' : 'error'}.png`")

		.d-flex.flex-column.align-items-center.justify-content-center.mx-auto(v-if="error && !isPending")
			img.payment-result__image-error(src="/assets/img/current/payment-warning.png")
			.d-flex.flex-column.w-100.payment-result__error
				h2.payment-result__title(v-html="$t('social.hPaymentFinalError')" :class="{'text-center': isMobile}")
				p.b3.text-grey.text-center.payment-result__contact-to {{ $t('social.contactTo') }}
				a.text-decoration-none.payment-result__link.is-error(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					pw-btn(:text="'Telegram'" :icon="'icon-telegram'")
				pw-btn-mail.mb-0.payment-result__link.is-error

		svg.position-absolute.payment-result__dashed-big(v-if='!isPending' :style="!isDarkTheme ?  'opacity: 0.5;': ''" xmlns="http://www.w3.org/2000/svg" width="330" height="473" fill="none")
			circle(cx="236.5" cy="236.5" r="236" :stroke="!isDarkTheme ? '#858B8F' : '#CBD2D8'" stroke-dasharray="20 20")
		svg.position-absolute.payment-result__dashed-medium(v-if='!isPending' :style="!isDarkTheme ?  'opacity: 0.5;': ''" xmlns="http://www.w3.org/2000/svg" width="329" height="329" fill="none")
			circle(cx="164.5" cy="164.5" r="164" :stroke="!isDarkTheme ? '#858B8F' : '#CBD2D8'" stroke-dasharray="20 20")
		svg.position-absolute.payment-result__dashed-small(v-if='!isPending' :style="!isDarkTheme ?  'opacity: 0.5;': ''" xmlns="http://www.w3.org/2000/svg" width="120" height="120" fill="none")
			circle(cx="60" cy="60" r="59.5" :stroke="!isDarkTheme ? '#858B8F' : '#CBD2D8'" stroke-dasharray="20 20")

		fb(v-if='analytics.fb_pixel' :id='analytics.fb_pixel' event-name='purchase' :need='true')
		tt(v-if='analytics.tt_pixel' :id='analytics.tt_pixel' event-name='purchase')
		ya(v-if='analytics.ya_pixel' :id='analytics.ya_pixel')
		ga(v-if='analytics.ga_pixel' :tid='analytics.ga_pixel')
		gw(v-if='analytics.gw_pixel' :id='analytics.gw_pixel')
		va(v-if='!error && analytics.vk_ads' :id='analytics.vk_ads' :ev="'subscribe'")
		vk(v-if='analytics.vk_pixel' :id='analytics.vk_pixel')
		//vk(v-if='info && info.vkPixelId' :id='info.vkPixelId')
		page-loader(v-if='isPending')
</template>

<script>
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';
import Vue from 'vue';
import { STORAGE } from '@/api/storage';
import { mapActions, mapGetters } from 'vuex';
import VueFacebookPixel from 'vue-analytics-facebook-pixel';
import FB from '@/components/Widgets/Facebook';
import TT from '@/components/Widgets/TikTok';
import YA from '@/components/Widgets/YandexCounter';
import GA from '@/components/Widgets/Google';
import GW from '@/components/Widgets/GoogleAdWords';
import VA from '@/components/Widgets/VKAdsCounter';
import VK from '@/components/Widgets/VKCounter';
import PageLoader from '@/components/Widgets/PageLoader';
import { locale } from '@/main';

Vue.use(VueFacebookPixel);

export default {
    name: 'PaymentResult',
    components: {
        PwBtn,
        PwBtnMail,
        PageLoader,
        fb: FB,
        tt: TT,
        ya: YA,
        ga: GA,
        gw: GW,
        va: VA,
        vk: VK,
    },
    data: () => ({
        error: false,
        info: STORAGE.getPayment(),
        paymentsList: STORAGE.getPaymentsList(),
        analytics: STORAGE.getAnalytics(),
        link: null,
        isDarkTheme: null,
        isBuyTokens: false,
        isIPhone: false,
    }),
    mounted() {
        const tgApp =
            window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user ? true : false;

        if (!this.info || this.info == null) {
            this.info = {};
        }

        if (
            !this.$route.query ||
            !(
                this.$route.query.orderId ||
                this.$route.query.OrderId ||
                this.$route.query.paymentId ||
                this.$route.query.PaymentId ||
                this.$route.query.session_id ||
                this.$route.query.paymentLinkId
            )
        ) {
            this.link = null;
            this.error = true;
            this.paymentDebug({
                routeQuery: this.$route.query,
                paymentsList: this.paymentsList,
                platform: window.navigator.platform,
                userAgent: this.getBrowserVersion(),
                examination: 'Первое условие',
            });
            return;
        }

        this.info.paymentId = this.$route.query.paymentId || this.$route.query.PaymentId || this.$route.query.id;

        if (this.$route.query.session_id) {
            this.info.sessionId = this.$route.query.session_id;
        }

        if (this.$route.query.paymentLinkId) {
            this.info.paymentLinkId = this.$route.query.paymentLinkId;
        }
        if (this.$route.query.transactionId) {
            this.info.transactionId = this.$route.query.transactionId;
        }

        if (
            !(
                this.paymentsList.includes(this.info.paymentId) ||
                this.paymentsList.includes(this.info.orderId) ||
                this.paymentsList.includes(this.info.sessionId)
            )
        ) {
            this.link = null;
            this.error = true;
            this.paymentDebug({
                routeQuery: this.$route.query,
                paymentsList: this.paymentsList,
                platform: window.navigator.platform,
                userAgent: this.getBrowserVersion(),
                examination: 'Второе условие',
            });
            return;
        }

        if (typeof this.info.sid == 'undefined') delete this.info.sid;

        this.info.locale = locale;

        if (this.analytics.clickid && this.analytics.projectId == this.info.id)
            this.info.pixelClickId = this.analytics.clickid;

        let data = _.cloneDeep(this.info);

        delete data.planDurationForever;
        delete data.orderId;
        delete data.orderSum;
        delete data.orderCurrency;
        delete data.id;
        delete data.plan;
        delete data.url;
        delete data.tg;
        delete data.pixelId;

        this.payment(data)
            .then(v => {
                if ((window.navigator.userAgent.match(/iPhone/i) && this.isMobile) || tgApp) {
                    let from = v.search('domain=');
                    let to = v.length;
                    let linkTg = v.substring(from, to).replace(/&/i, '?');

                    this.link = `https://t.me/${linkTg.replace(/domain=/i, '')}`;
                } else {
                    this.link = v;
                }

                if (this.info.pixelId != null) {
                    Vue.analytics.fbq.init(this.info.pixelId);
                    Vue.analytics.fbq.event('Purchase', {
                        value: this.info.pixelPurchaseValue,
                        currency: this.info.orderCurrency,
                        content_name: this.info.url,
                        amount: this.info.orderSum,
                    });
                }

                if (this.analytics.vk_pixel != null) {
                    setTimeout(() => {
                        if (window[this.analytics.vk_pixel])
                            window[this.analytics.vk_pixel].Goal('subscribe', { value: this.info.orderSum });
                    }, 400);
                }
            })
            .catch(e => {
                console.log(e, 'error');
                this.paymentDebug({
                    routeQuery: this.$route.query,
                    paymentsList: this.paymentsList,
                    platform: window.navigator.platform,
                    userAgent: this.getBrowserVersion(),
                    link: this.link,
                    error: e,
                    examination: `Ошибка в запросе axios.post(APIURL/payments/success): ${e}`,
                    data: `Отправляемые параметры: ${data}`,
                });
                this.info = null;
                this.link = null;
                this.error = true;
            });
    },
    beforeMount() {
        this.isDarkTheme = this.info.isDarkTheme;
        import('@/assets/css/sass/themes/piaf.' + (this.isDarkTheme ? 'dark' : 'light') + '.figma.scss');
    },
    methods: {
        ...mapActions({
            payment: 'users/payment',
            paymentDebug: 'users/paymentDebug',
        }),
        /*openTelegramWebAppLink() {
			const tgApp = window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user ? true : false;

			let from = this.link.search('domain=');
			let to = this.link.length;
			let linkTg = this.link.substring(from,to).replace(/&/i, "?");

			if (tgApp) {
				window.Telegram.WebApp.openTelegramLink(`https://t.me/${linkTg.replace(/domain=/i, "")}`);
				window.Telegram.WebApp.close();
			}
		},*/
        getBrowserVersion() {
            const userAgent = navigator.userAgent;
            const browserName = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

            if (/trident/i.test(browserName[0])) {
                const rv = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
                return `IE ${rv[1] || ''}`;
            }

            const version = browserName[2] ? browserName[2] : null;
            const browser = browserName[1] ? browserName[1] : null;

            if (browser === null || version === null) {
                return 'Unknown Browser';
            }

            return `${browser} ${version}`;
        },
    },
    computed: {
        ...mapGetters({
            isPending: 'users/isPending',
        }),
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.payment-result {
    padding: 25px 0 55px;
    height: 100%;

    @include respond-below(md) {
        padding: 10px 0 30px;
        height: auto;
    }

    &__title {
        margin-bottom: 30px !important;

        @include respond-below(md) {
            font-size: 22px !important;
            line-height: 27px !important;
            margin-bottom: 24px !important;
        }
    }

    &__contact-to {
        margin-bottom: 14.5px;

        @include respond-below(sm) {
            margin-bottom: 8.5px;
        }
    }

    &__info {
        z-index: 1;
        padding: 20px;
        max-width: 463px;
        border-radius: var(--border-radius-rounded);
        background-color: var(--foreground-color);
        box-shadow: var(--box-shadow-container);
        margin-right: 104px;
        margin-top: 17px;

        @include respond-below(lg) {
            margin-right: 30px;
        }

        @include respond-below(md) {
            margin-right: 0;
        }

        @include respond-below(sm) {
            padding: 15px 17px 18px;
            margin-top: 0;
        }
    }

    &__contact-us {
        margin-bottom: 15px;

        @include respond-below(sm) {
            margin-top: -3px;
        }
    }

    &__txt {
        margin-top: -1px;
        margin-bottom: 30px;

        @include respond-below(xs) {
            margin-top: -2px;
            margin-bottom: 24px;
        }
    }

    &__support {
        @include respond-below(sm) {
            margin-bottom: 10px !important;
        }
    }

    &__instruction-wrap {
        position: relative;
        margin-top: -1px;
        margin-bottom: 11px;

        @include respond-below(xs) {
            margin-top: -2px;
            margin-bottom: 9px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 18px;
            left: 7px;
            height: 51%;
            border: 1px dashed var(--border-dashed-color);

            @include respond-below(xs) {
                top: 9px;
                left: 4px;
            }
        }
    }

    &__instruction {
        position: relative;
        padding-left: 29px;
        margin-bottom: 19px;

        @include respond-below(xs) {
            font-size: 14px !important;
            line-height: 18px !important;
            padding-left: 20px;
            margin-bottom: 15px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: var(--brand-primary);
            transform: translateY(-50%);

            @include respond-below(xs) {
                width: 10px;
                height: 10px;
                top: 9px;
            }
        }
    }

    &__open-bot {
        margin-bottom: 30px;

        @include respond-below(sm) {
            margin-bottom: 25px;

            .btn {
                min-height: 55px;
            }
        }
    }

    &__button {
        max-width: 351px;
    }

    &__image-payment {
        position: absolute;
        top: 16%;
        right: 5.5%;
        max-width: 122px;
        max-height: 122px;

        @media (max-width: 992px) {
            top: 12.5%;
            right: 0.7%;
            max-width: 26.8%;
            max-height: 23.2%;
        }
    }

    &__image {
        max-width: 495px;
        max-height: 400px;
        margin-right: -78px;
        margin-top: -129px;

        @media (max-width: 992px) {
            max-width: 291px;
            margin: 0 auto -62px;
        }

        img {
            z-index: 1;
            width: 100%;
            height: 100%;
        }
    }

    &__link {
        min-height: 52px;
        margin-bottom: 15px;

        .pw-btn {
            min-height: 52px;
        }

        &.is-error {
            margin-right: 9px;
            margin-left: 9px;
        }

        @include respond-below(sm) {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: 10.5px;
        }
    }

    &__error {
        z-index: 1;
        padding: 30.5px 30px;
        max-width: 438px;
        box-shadow: var(--box-shadow-container);
        border-radius: var(--border-radius-rounded);
        background-color: var(--foreground-color);

        @include respond-below(sm) {
            padding: 20px 17px;
            max-width: 100%;
        }
    }

    &__image-error {
        width: 274px;
        height: 274px;
        margin-top: -32px;
        margin-left: 42px;
        margin-bottom: -115px;
        z-index: 10;

        @include respond-below(sm) {
            margin-top: -56px;
            margin-bottom: -107px;
        }
    }

    &__dashed-big {
        top: -210px;
        right: -50px;

        @include respond-below(md) {
            display: none;
        }
    }

    &__dashed-medium {
        bottom: 82px;
        left: 8px;

        @include respond-below(md) {
            display: none;
        }
    }

    &__dashed-small {
        top: 0;
        right: 35%;

        @include respond-below(md) {
            display: none;
        }
    }

    &__dashed-supersmall {
        bottom: 16px;
        right: 30%;

        @include respond-below(md) {
            display: none;
        }
    }
}
</style>
